module.exports = [
  { "class": "fad fa-file-archive", "ext": ".zip .z .gz .Z .tgz .tar.gz .tar .rar .war" },
  { "class": "fab fa-app-store-ios", "ext": ".ipa" },
  { "class": "fad fa-file-alt", "ext": ".plist" },
  { "class": "fab fa-apple", "ext": ".DS_Store", "hide": true },
  { "class": "fab fa-android", "ext": ".apk" },
  { "class": "fad fa-file-pdf", "ext": ".pdf" },
  { "class": "fad fa-file-code", "ext": ".html .json .js .xml .css" },
  { "class": "fad fa-file-image", "ext": ".jpg .png .gif .jpeg .bmp" },
  { "class": "fad fa-file-audio", "ext": ".mp3 .wav" },
  { "class": "fad fa-file", "ext": "default" },
  { "class": "fad fa-folder", "ext": "folder" }
];