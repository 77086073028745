module.exports = [
  {
    "quote": "In the 21<sup>st</sup> century, the robot will take the place which slave labor occupied in ancient civilization.",
    "author": "Nikola Tesla"
  },
  {
    "quote": "If you lend your consciousness to someone else, you're a robot.",
    "author": "Prince"
  },
  {
    "quote": "What's casual for a robot isn't necessarily what's casual for a human.",
    "author": "Alan Tudyk"
  },
  {
    "quote": "Personally, I'm not afraid of a robot uprising. The benefits far outweigh the threats.",
    "author": "Daniel H. Wilson"
  },
  {
    "quote": "Even a manically depressed robot is better to talk to than nobody.",
    "author": "Douglas Adams"
  },
  {
    "quote": "Will robots inherit the earth? Yes, but they will be our children.",
    "author": "Marvin Minsky"
  },
  {
    "quote": "I believe that robots should only have faces if they truly need them.",
    "author": "Donald A. Norman "
  }
];